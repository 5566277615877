import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from, map, Observable, switchMap, take } from 'rxjs';

import { environment } from '@env/environment';
import { TRole } from '@core/domain/types';
import {
  MUTATION_LOGOUT,
  QUERY_GET_AUTH_USER,
  QUERY_GET_URL_REDIRECT,
} from '../gql/queries';
import {
  AuthUserResponse,
  GraphQLData,
  LoginRedirectResponse,
} from '../types/auth.types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly apolloClient = inject(Apollo);

  getCurrentIp() {
    return from(
      fetch(environment.ipPublicUrl).then((data) => data.json()),
    ).pipe(map(({ ip }) => ip));
  }

  getAuthUrl(role: TRole | null) {
    return this.getCurrentIp().pipe(
      switchMap((ip) => {
        return this.apolloClient
          .query({
            query: QUERY_GET_URL_REDIRECT,
            variables: {
              loginRedirectRequestDto: {
                ip,
                referer: environment.callbackUrl,
                role,
              },
            },
          })
          .pipe(
            take(1),
            map((res) => ({
              data: (res as unknown as GraphQLData<LoginRedirectResponse>).data
                .redirectUrl,
            })),
          );
      }),
    );
  }

  validateToken(token: string): Observable<{ token: string }> {
    return this.apolloClient
      .query({
        query: QUERY_GET_AUTH_USER,
        variables: {
          authUserDto: { token },
        },
      })
      .pipe(
        take(1),
        map((res) => ({
          token: (res as unknown as GraphQLData<AuthUserResponse>).data.authUser
            .token,
        })),
      );
  }

  logout() {
    return this.apolloClient.mutate({ mutation: MUTATION_LOGOUT });
  }
}
