import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  async getItem(key: string) {
    const data = JSON.parse(localStorage.getItem(key) as string);
    return data;
  }

  async setItem<T>(key: string, item: T) {
    const data = JSON.stringify(item);
    localStorage.setItem(key, data);
  }

  async clear() {
    localStorage.clear();
  }
}
