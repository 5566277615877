import { Component, Input } from '@angular/core';

import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'dialog-confirm',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './dialog-confirm.component.html',
})
export class DialogConfirmComponent {
  @Input() icon: 'round-question' | 'round-x' | 'exclamation-triangle';
  @Input() title: string;
}
