import { Component, Input } from '@angular/core';

import { IAccount } from '@core/domain/interfaces';

@Component({
  selector: 'account-info',
  standalone: true,
  imports: [],
  templateUrl: './account-info.component.html',
})
export class AccountInfoComponent {
  @Input() account: IAccount;
}
