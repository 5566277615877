import {
  TErrorFileCapabilities,
  TUploadFileCapabilitiesResponse,
} from '@core/domain/types';

export class UploadFileResponseEntity
  implements TUploadFileCapabilitiesResponse
{
  readonly status: 'ERROR' | 'OK';
  readonly message: string;
  readonly errorCode?: string;
  readonly fileErrors?: TErrorFileCapabilities[];

  private constructor(resp: TUploadFileCapabilitiesResponse) {
    this.status = resp.status;
    this.message = resp.message;
    this.errorCode = resp.errorCode;
    this.fileErrors = resp.fileErrors;
  }

  static fromJson(resp: TUploadFileCapabilitiesResponse) {
    return new UploadFileResponseEntity({
      status: resp.status ?? 'ERROR',
      message: resp.message ?? '',
      errorCode: resp.errorCode ?? '',
      fileErrors: resp.fileErrors ?? null,
    });
  }

  isSuccess() {
    return this.status === 'OK';
  }
}
