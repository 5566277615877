<section class="xs:p-4 lg:p-0 xs:mb-2 lg:mb-8">
  <div class="single-card">
    <div class="flex flex-col gap-3 p-2">
      <h3 class="text-lg font-bold">Información de usuario</h3>
      <div class="flex xs:flex-col lg:flex-row lg:justify-between xs:gap-4 lg:gap-0">
        <div class="flex flex-col">
          <p class="text-sm">Nombre del titular</p>
          <strong class="font-bold">{{ account?.name }}</strong>
        </div>
        <div class="flex flex-col">
          <p class="text-sm">Cuenta</p>
          <strong class="font-bold">{{ account?.account }}</strong>
        </div>
        <div class="flex flex-col">
          <p class="text-sm">Municipio</p>
          <strong class="font-bold">{{ account?.stateName }}</strong>
        </div>
        <div class="flex flex-col">
          <p class="text-sm">Dirección</p>
          <strong class="font-bold">{{ account?.address }}</strong>
        </div>
        <div class="flex flex-col">
          <p class="text-sm">Cobertura Vanti Max</p>
          <strong class="font-bold">{{ account?.hasVantiMax ? 'Si' : 'No' }}</strong>
        </div>
        <div class="flex flex-col">
          <p class="text-sm">Celular</p>
          <strong class="font-bold">{{ account?.cellphone }}</strong>
        </div>
      </div>
    </div>
  </div>
</section>
