import { HttpInterceptorFn } from '@angular/common/http';

export const apiInterceptor: HttpInterceptorFn = (req, next) => {
  const clonedRequest = req.clone({
    setHeaders: {
      'x-application': 'smart-mobility',
      'x-role': 'tecnico',
    },
  });

  return next(clonedRequest);
};
