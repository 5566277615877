import { inject, Injectable } from '@angular/core';
import { catchError, map, of, startWith, take } from 'rxjs';

import { TPaginator } from '@core/domain/types';
import { listSegmentedZone, listZone } from '@core/modules/admin/data/entities';
import { TSegmentedZonesFilter } from '@core/modules/admin/data/types';
import {
  ICreateSegmentedZone,
  IUpdateSegmentedZone,
} from '@core/modules/admin/data/interfaces';
import { AdminService } from '@core/modules/admin/services';

@Injectable({
  providedIn: 'root',
})
export class AdminBloc {
  private readonly adminService = inject(AdminService);

  initCapabilitiesData(paginator: TPaginator) {
    return this.adminService.segmentedZonesInit(paginator).pipe(
      startWith({ type: 'INIT_LOADING', resp: null }),
      map((resp) => {
        if ('type' in resp) {
          return resp;
        }

        return {
          type: 'RESPONSE',
          resp: {
            segmentedZones: {
              ...resp.segmentedZones,
              data: listSegmentedZone(resp.segmentedZones.data),
            },
            zoneList: listZone(resp.zoneList),
          },
        };
      }),
    );
  }

  filterSegmentedZonesData(
    paginator: TPaginator,
    filters: Partial<TSegmentedZonesFilter>,
  ) {
    return this.adminService.filterSegmentedZones(paginator, filters).pipe(
      take(1),
      map((resp) => {
        return resp.segmentedZones;
      }),
    );
  }

  getLastIndex(filial: string) {
    return this.adminService.getZoneLastIndex(filial).pipe(
      take(1),
      catchError(() => of('')),
    );
  }

  newSegmentedZone(segmentedZone: ICreateSegmentedZone) {
    return this.adminService.createSegmentedZone(segmentedZone).pipe(take(1));
  }

  deleteSegmentedZoneById(id: string) {
    return this.adminService.removeSegmentedZone(id).pipe(take(1));
  }

  updateSegmentedZones(segmentedZone: IUpdateSegmentedZone) {
    return this.adminService.updateSegmentedZones(segmentedZone).pipe(take(1));
  }
}
