import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { take, map } from 'rxjs';

import {
  GET_FILTERS_DATA,
  GET_INIT_FILTERS_DATA,
  GET_INIT_FILTERS_DETAILS_DATA,
} from '../data/gql';
import {
  ICapabilitiesFilters,
  ICapabilitiesByDayInitData,
  ICapabilityMonth,
  IFiltersInitData,
} from '../data/types';

@Injectable({
  providedIn: 'root',
})
export class CapabilitiesService {
  private readonly apolloClient = inject(Apollo);

  getFiltersData(filters: ICapabilitiesFilters) {
    return this.apolloClient
      .query<IFiltersInitData>({
        query: GET_INIT_FILTERS_DATA,
        variables: { filters },
      })
      .pipe(
        take(1),
        map((resp) => resp.data),
      );
  }

  getFiltersChartData(filters: ICapabilitiesFilters) {
    return this.apolloClient
      .query<{ capabilitiesByMonth: ICapabilityMonth[] }>({
        query: GET_FILTERS_DATA,
        variables: { filters },
      })
      .pipe(
        take(1),
        map((resp) => resp.data),
      );
  }

  getCapabilitiesViewDataByZoneId(
    zoneId: string,
    filters: ICapabilitiesFilters,
  ) {
    return this.apolloClient
      .query<ICapabilitiesByDayInitData>({
        query: GET_INIT_FILTERS_DETAILS_DATA,
        variables: {
          pagination: {
            limit: 10,
            page: 1,
          },
          segmentedZonesFilters: {
            zoneId,
          },
          filters,
          filtersBags: filters,
        },
      })
      .pipe(
        take(1),
        map(({ data }) => data),
      );
  }
}
