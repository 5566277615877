import { PAGE_PATHS } from '../helpers';
import { IPermissionsUser } from '../interfaces';

export function RouteAutorizedEntity(permissions: IPermissionsUser) {
  const modulesMap = new Map(Object.entries(PAGE_PATHS));
  const permissionsMap = _permissionToRoute();

  function _permissionToRoute() {
    const routePermissionsEntries = Object.entries(
      permissions?.module?.at(0) ?? {},
    ).map<[string, string[]]>(([key, value]) => [modulesMap.get(key)!, value]);

    return new Map(routePermissionsEntries);
  }

  const getRouteSplit = (route: string) => {
    const routeSplit = route?.split('/');
    const setRoutes = new Set(routeSplit);
    setRoutes.delete('');

    return Array.from(setRoutes);
  };

  const hasAccessbyRole = (routePath: string, permission: string) => {
    const [route] = getRouteSplit(routePath);
    const routeItem = permissionsMap.get(route);

    return !!routeItem?.includes('all') || !!routeItem?.includes(permission);
  };

  const validateRoute = (routePath: string) => {
    const [route] = getRouteSplit(routePath);
    return permissionsMap.has(route);
  };

  return {
    permissionsMap,
    validateRoute,
    hasAccessbyRole,
    getRouteSplit,
  };
}
