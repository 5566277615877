import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { DialogThinkingComponent } from '../dialog-thinking/dialog-thinking.component';

@Component({
  selector: 'error-schedule',
  standalone: true,
  imports: [DialogThinkingComponent],
  templateUrl: './error-schedule.component.html',
})
export class ErrorScheduleComponent {
  private readonly _dialogRef = inject(MatDialogRef<ErrorScheduleComponent>);

  onClose() {
    this._dialogRef.close();
  }
}
