import { Component, inject, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { Router } from '@angular/router';

import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { IModuleItem } from '@core/domain/interfaces';

@Component({
  selector: 'side-menu-item',
  standalone: true,
  imports: [SvgIconComponent, NgClass],
  templateUrl: './side-menu-item.component.html',
  styleUrl: './side-menu-item.component.css',
})
export class SideMenuItemComponent {
  @Input() isOpen = false;
  @Input() item: IModuleItem | null = null;
  private readonly router = inject(Router);

  get isActiveRoute() {
    return this.router.isActive(this.item?.url ?? '', false);
  }

  doNavigateAction() {
    this.router.navigate([this.item?.url ?? '']);
  }
}
