<div
  class="relative transform rounded-xl bg-white text-center shadow-xl transition-all sm:w-full sm:max-w-xl"
>
  <div class="bg-white xs:p-4 lg:p-12">
    <div class="xs:flex xs:flex-col lg:grid lg:items-center">
      <button
        class="absolute top-5 right-4 text-gray-500 hover:text-gray-700 w-4 h-4"
        aria-label="Cerrar"
        (click)="closeModal()"
      >
        <svg-icon icon="x-icon" />
      </button>

      <div class="text-center">
        <div class="xs:flex lg:hidden xs:justify-center xs:py-6 lg:p-0">
          <picture class="w-36">
            <img src="assets/images/vanti-thinking.png" alt="thinking vanti">
          </picture>
        </div>
        <h2 class="text-2xl font-bold text-accent" id="modal-title">
          Ingresa el código enviado
        </h2>
        <div class="mt-5">
          <p class="text-black">
            Se ha enviado el
            <span class="font-bold">
              código OTP al celular {{ phone | maskedText:3:2 }} y al correo electrónico
            </span>
            registrado del cliente, por favor verificalo e ingrésalo a continuación.
          </p>
        </div>

        <opt-input [length]="6" (onValueChange)="otpCode($event)" />

        <div class="mt-5 flex justify-center">
          <p class="text-black xs:w-52 lg:w-full">
            ¿Aún no recibes el código?
            <span
              class="text-gray-500 ms-2 font-bold cursor-pointer hover:text-gray-700"
            >
              Reenviar código
            </span>
          </p>
        </div>

        <div class="mt-5">
          <p class="text-black">
            Tienes <span class="font-bold">{{ $timer() }} segundos</span> para ingresar el
            código
          </p>
        </div>
      </div>
    </div>

    <div class="px-4 xs:pt-6 lg:pt-12 flex gap-4 xs:flex-col lg:flex-row lg:justify-around">
      <button
        type="button"
        class="btn btn-outline min-w-40"
        (click)="closeModal()"
      >
        Regresar
      </button>
      <button
        type="button"
        class="btn btn-primary min-w-40"
        (click)="onConfirmOPTValid()"
        [disabled]="!$codeOtp()"
      >
        Continuar
      </button>
    </div>
  </div>
</div>
