import {
  Component,
  computed,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TOption } from '@core/domain/types';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'sm-select',
  standalone: true,
  imports: [SvgIconComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SmSelectComponent),
      multi: true,
    },
  ],
  templateUrl: './sm-select.component.html',
  styleUrl: './sm-select.component.css',
})
export class SmSelectComponent implements ControlValueAccessor {
  readonly $options = signal<TOption[]>([]);
  readonly $isDropdownOpen = signal(false);
  readonly $value = signal<string | null>(null);

  @Input() placeholder: string = 'Select an option';
  @Input() set options(opts: TOption[]) {
    this.$options.set(opts);
  }

  @Input() set value(value: string) {
    this.writeValue(value);
  }

  @Output() change = new EventEmitter<string | null>();

  isDisabled: boolean = false;

  readonly $label = computed(() => {
    return this.$value()
      ? this.$options().find((opt) => opt.value === this.$value())?.label
      : this.placeholder;
  });

  readonly $icon = computed(() => {
    return this.$isDropdownOpen() ? 'chevron-up' : 'chevron-down';
  });

  // eslint-disable-next-line no-empty-function, @typescript-eslint/no-unused-vars
  onChange = (value: string | null) => {};

  // eslint-disable-next-line no-empty-function
  onTouched = () => {};

  writeValue(value: string | null): void {
    this.$value.set(value);
  }

  registerOnChange(fn: (value: string | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onBlur() {
    this.onTouched();
  }

  toggleDropdown() {
    this.$isDropdownOpen.update((isOpen) => !isOpen);
  }

  selectOption(opt: TOption) {
    this.toggleDropdown();
    this.onChange(opt.value);
    this.change.emit(opt.value);
    this.$value.set(opt.value);
  }

  onKeydown(event: KeyboardEvent, option: TOption) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.selectOption(option);
    }
  }
}
