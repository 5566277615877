import { Component, EventEmitter, inject, Output } from '@angular/core';
import {
  ReactiveFormsModule,
  FormBuilder,
  Validators,
  FormGroup,
} from '@angular/forms';
import { map } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { NgxMaskDirective } from 'ngx-mask';

import { SmSelectComponent } from '../sm-select/sm-select.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'scheduler-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SvgIconComponent,
    SmSelectComponent,
    NgxMaskDirective,
  ],
  templateUrl: './scheduler-form.component.html',
})
export class SchedulerFormComponent {
  private readonly _formBuilder = inject(FormBuilder);

  readonly formGroup = this._formBuilder.group({
    account_contract: [
      '',
      [
        Validators.required,
        Validators.min(0),
        Validators.pattern(/^[0-9,$]*$/),
      ],
    ],
    name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]+$/)]],
    cellphone: ['', [Validators.pattern(/^[0-9,$]*$/)]],
    refer: ['', [Validators.pattern(/^[0-9,$]*$/)]],
    'terms-conditions': [false, [Validators.requiredTrue]],
    'allow-vanti': [false, [Validators.requiredTrue]],
    will_attend: 'YES',
    'extra-name': '',
    'extra-phone': '',
    'extra-terms-conditions': false,
  });

  readonly $showExtraData = toSignal(
    this.formGroup.valueChanges.pipe(
      map((value) => {
        return value.will_attend === 'NO';
      }),
    ),
  );

  @Output() onSubmit = new EventEmitter<FormGroup>();

  submitForm() {
    this.onSubmit.emit(this.formGroup);
  }
}
