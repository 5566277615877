import { jwtDecode } from 'jwt-decode';
import { IPayloadUserToken } from '../interfaces';

export const getPayload = (token: string): IPayloadUserToken | null => {
  try {
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
};
