<section class="flex bg-white lg:pr-16 lg:pl-28 xl:pr-20 xl:pl-32 py-4 justify-between">
  <div class="flex gap-2 items-center" [ngClass]="{ 'lg:ml-56' : isOpen }">
    <picture class="w-5">
      <img src="assets/images/vanti-icon.png" alt="vanti icon">
    </picture>
    <h1 class="font-extrabold text-xl">{{ title }}</h1>
  </div>
  <div class="flex gap-3 items-center">
    <div class="flex flex-col items-end">
      <p class="font-bold">{{ user.name }}</p>
      <p class="text-sm font-normal">{{ user.role }}</p>
    </div>
    <div class="w-12 h-12 rounded-full flex items-center justify-center bg-neutral-60">
      <span class="font-bold">{{ userShortName() }}</span>
    </div>
  </div>
</section>
