import { ISegmentedNet } from '../interfaces';

export const listSegmentedNets = (
  list: Partial<ISegmentedNet>[],
): ISegmentedNet[] =>
  list?.map((item) => SegmentedNetEntity.fromJson(item).toJson()) ?? [];

export class SegmentedNetEntity implements ISegmentedNet {
  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly created_at: string;
  readonly updated_at: string;

  private constructor(net: ISegmentedNet) {
    this.id = net.id;
    this.code = net.code;
    this.name = net.name;
    this.created_at = net.created_at;
    this.updated_at = net.updated_at;
  }

  static fromJson(net: Partial<ISegmentedNet>) {
    return new SegmentedNetEntity({
      id: net.id ?? '',
      code: net.code ?? '',
      name: net.name ?? '',
      created_at: net.created_at ?? '',
      updated_at: net.updated_at ?? '',
    });
  }

  toJson(): ISegmentedNet {
    return {
      ...this,
    };
  }
}
