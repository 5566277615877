import { Component, HostBinding, Input } from '@angular/core';

import { TSvgIcons } from '@core/domain/types';

@Component({
  selector: 'svg-icon',
  standalone: true,
  imports: [],
  template: ``,
  styleUrl: './svg-icon.component.css',
})
export class SvgIconComponent {
  @HostBinding('style.-webkit-mask-image')
  private _path!: string;

  @Input()
  public set icon(iconName: TSvgIcons) {
    this._path = !!iconName ? `url("assets/svgs/${iconName}.svg")` : 'url("")';
  }
}
