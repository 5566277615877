<div 
  class="flex flex-col bg-white w-20 px-2 py-4 h-screen gap-4 shadow-lg"
  [ngClass]="{ 'w-20': !isOpen(), 'w-80': isOpen() }"
>
  <section class="w-full h-12 flex items-center justify-center">
    <div class="flex justify-start w-full border-white pl-5">
      <span class="w-6 h-6 cursor-pointer" (click)="doCheck()">
        <svg-icon [icon]="iconMenu" />
      </span>
    </div>
  </section>
  <section class="flex-1 flex flex-col gap-2">
    @for (item of listItems; track item.label) {
      <side-menu-item [isOpen]="isOpen()" [item]="item" />
    }
  </section>
  <section class="flex flex-col gap-2 min-h-[116px] mb-3">
    <div class="flex items-center cursor-pointer" (click)="doLogout()">
      <div class="w-14 h-12 flex items-center justify-center">
        <span class="w-7 h-7 ">
          <svg-icon icon="go-out" />
        </span>
      </div>
      <p class="text-accent-clear" [ngClass]="{ 'hidden': !isOpen() }">Salida</p>
    </div>
    @if (isOpen()) {
      <div class="flex flex-col gap-2 items-center">
        <span class="text-sm font-normal text-accent-clear">{{ version }}</span>
        <span class="text-sm font-normal text-accent-clear">{{ copyRight }}</span>
      </div>
    }
  </section>
</div>
