import { gql } from 'apollo-angular';

export const GET_INIT_FILTERS_DATA = gql`
  query Query($filters: CapabilityFiltersDto!) {
    listOperations {
      id
      name
      label
    }
    zoneList {
      id
      code
      name
    }
    capabilitiesByMonth(filters: $filters) {
      active
      available
      date
      scheduled
      total
    }
  }
`;

export const GET_FILTERS_DATA = gql`
  query Query($filters: CapabilityFiltersDto!) {
    capabilitiesByMonth(filters: $filters) {
      active
      available
      date
      scheduled
      total
    }
  }
`;

export const GET_INIT_FILTERS_DETAILS_DATA = gql`
  query CapabilitiesSegmented(
    $filters: CapabilityFiltersDto!
    $filtersBags: CapabilityFiltersDto!
    $pagination: PaginationDto!
    $segmentedZonesFilters: SegmentedZonesFilters
  ) {
    capabilitiesByDay(filters: $filters) {
      zone_id
      zone_name
      segmented_zone_id
      segmented_zone_name
      initial_capability
      scheduled
      available
      percentage_am
      percentage_pm
      available_am
      available_pm
    }
    capabilitiesSegmentedBags(filters: $filtersBags) {
      available
      available_pm
      available_am
      bag
      initial_capability
      percentage
      percentage_am
      percentage_pm
      scheduled
      segmented_zone_id
      segmented_zone_name
      zone_id
      zone_name
    }
    segmentedZones(pagination: $pagination, filters: $segmentedZonesFilters) {
      data {
        code
        id
        name
      }
    }
  }
`;
