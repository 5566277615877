import { Component, inject, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  RecaptchaModule,
  RecaptchaV3Module,
  ReCaptchaV3Service,
} from 'ng-recaptcha';
import { catchError, of, switchMap } from 'rxjs';

import { NotifyService } from '@core/shared/services';
import { AuthBlocService } from '@core/blocs';
import { TRole } from '@core/domain/types';
import { environment } from '@env/environment';
import { SmSelectComponent } from '@core/shared/components';

@Component({
  selector: 'page-login',
  standalone: true,
  imports: [
    SmSelectComponent,
    RecaptchaModule,
    RecaptchaV3Module,
    ReactiveFormsModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.styles.css',
})
export class LoginComponent {
  private readonly authBloc = inject(AuthBlocService);
  private readonly recaptchaV3Service = inject(ReCaptchaV3Service);
  private readonly notify = inject(NotifyService);
  public readonly role = new FormControl<TRole>('' as TRole);
  public readonly isFetching = signal(false);

  public readonly options = [
    { value: 'agente canales', label: 'Agente Canales' },
    { value: 'consulta', label: 'Consulta' },
    { value: 'administrador', label: 'Administrador' },
  ];

  get canShowRoleSelector() {
    return !environment.production;
  }

  getReCaptchaToken() {
    return this.recaptchaV3Service
      .execute('init-form-signin')
      .pipe(catchError((err) => of({ success: false, message: err.message })));
  }

  onSignIn() {
    this.isFetching.set(true);
    return this.getReCaptchaToken()
      .pipe(
        switchMap(() => {
          return this.authBloc.getUrlAuth(this.role.value);
        }),
      )
      .subscribe(({ success, message }) => {
        if (!success) {
          this.notify.error('Espera', message!);
          this.isFetching.set(false);
        }
      });
  }
}
