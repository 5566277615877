import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { UserStateService } from '@core/states';

export const authGuard: CanActivateFn = async (_, __) => {
  const userState = inject(UserStateService);
  const router = inject(Router);

  const isAuthActive = userState.isAuthActive();

  if (!isAuthActive) {
    router.navigate(['login'], { replaceUrl: true });
    return false;
  }

  return true;
};
