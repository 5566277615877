<div class="p-4">
  <div class="bg-white p-4 flex flex-col gap-2 w-[500px] rounded-lg">
    <div class="flex justify-center">
      <span class="w-8 h-8">
        <svg-icon style="background-color: red;" [icon]="icon" />
      </span>
    </div>
    <div class="flex justify-center">
      <h3 class="font-bold text-2xl">{{ title }}</h3>
    </div>
    <div class="flex">
      <ng-content select="[id=description]" />
    </div>
    <div class="flex">
      <ng-content select="[id=actions]" />
    </div>
  </div>  
</div>