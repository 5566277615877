<div class="flex items-center gap-6">
  <div class="flex items-center gap-2">
    <p>Mostrando</p>
    <sm-select
      [options]="$listOptions()"
      placeholder="0"
      [value]="$pageSize()"
      (change)="changeLimit($event)"
    />
    <p>
      de <span>{{ $totalItems() }}</span>
    </p>
  </div>
  <div class="flex gap-4 items-center">
    <button
      class="w-4 h-4 cursor-pointer disabled:opacity-25"
      [disabled]="$isDisabledPrev()"
      (click)="prevPage()"
    >
      <svg-icon icon="chevron-left" />
    </button>
    @for (page of $pageList(); track page) {
      <span
        class="cursor-pointer"
        [class.selected]="$page() === page"
        (click)="setPage(page)"
      >
        {{ page }}
      </span>
    }
    <button
      class="w-4 h-4 cursor-pointer disabled:opacity-25"
      [disabled]="$idDisabledNext()"
      (click)="nextPage()"
    >
      <svg-icon icon="chevron-right" />
    </button>
  </div>
</div>
