import { ChartData } from 'chart.js';
import { DateTime } from 'luxon';

import { ICapabilityMonth, TChartDataMap } from '../types';
import { DateTimeAdapter } from '@core/domain/adapters';

export class ChartCapabilitiesBuilder {
  constructor(
    readonly datetime: DateTime,
    readonly data: ICapabilityMonth[],
  ) {}

  get lastDay() {
    return DateTimeAdapter.lastDayMonth(this.datetime.toFormat('yyyy-MM-dd'))
      .day;
  }

  listDays() {
    return Array.from({ length: this.lastDay }, (_, idx) => `${idx + 1}`);
  }

  dataToMap() {
    const entries = this.data.map<[number, ICapabilityMonth]>((d) => [
      DateTimeAdapter.fromISO(d.date).day,
      d,
    ]);

    return new Map<number, ICapabilityMonth>(entries);
  }

  mapData(): TChartDataMap {
    const dataChart: TChartDataMap = {
      scheduled: [],
      available: [],
      total: [],
    };
    const mapData = this.dataToMap();

    this.listDays().forEach((day) => {
      if (!mapData.has(+day)) {
        dataChart.available.push(0);
        dataChart.scheduled.push(0);
        dataChart.total.push(0);
        return;
      }

      const item = mapData.get(+day);

      if (item.active) {
        dataChart.available.push(item.available);
        dataChart.scheduled.push(item.scheduled);
        dataChart.total.push(0);
        return;
      }

      dataChart.available.push(0);
      dataChart.scheduled.push(0);
      dataChart.total.push(item.total);
    });

    return dataChart;
  }

  build(): ChartData {
    const data = this.mapData();

    return {
      labels: this.listDays(),
      datasets: [
        {
          data: data.scheduled,
          label: 'Agendado',
          backgroundColor: '#f6cc5c',
        },
        {
          data: data.available,
          label: 'Disponible',
          backgroundColor: '#113455',
        },
        {
          data: data.total,
          label: 'Fecha no disponible para agendar',
          backgroundColor: '#EBEDF0',
        },
      ],
    };
  }
}
