import { gql } from 'apollo-angular';

export const INIT_SEGMENTED_ZONES_PAGE = gql`
  query InitSegmentedZones($pagination: PaginationDto!) {
    zoneList {
      code
      id
      name
    }
    segmentedZones(pagination: $pagination) {
      data {
        id
        code
        name
        zone {
          code
          id
          name
        }
        segmentedNets {
          code
          id
          name
        }
      }
      page
      total
      totalPages
    }
  }
`;

export const SEGMENTED_ZONES_PAGE_FILTERS = gql`
  query Query($pagination: PaginationDto!, $filters: SegmentedZonesFilters) {
    segmentedZones(pagination: $pagination, filters: $filters) {
      data {
        id
        code
        name
        zone {
          code
          id
          name
        }
        segmentedNets {
          code
          id
          name
        }
      }
      page
      total
      totalPages
    }
  }
`;

export const CREATE_SEGMENTED_ZONE = gql`
  mutation Mutation($newSegmentedZone: CreateSegmentedZoneDTO!) {
    createSegmentedZone(newSegmentedZone: $newSegmentedZone)
  }
`;

export const UPDATE_SEGMENTED_ZONE = gql`
  mutation Mutation($segmentedZone: UpdateSegmentedZoneDTO!) {
    updateSegmentedZone(segmentedZone: $segmentedZone)
  }
`;

export const DELETE_SEGMENTED_ZONE = gql`
  mutation Mutation($segmentedZoneId: String!) {
    deleteSegmentedZone(segmentedZoneId: $segmentedZoneId)
  }
`;

export const FILIAL_LAST_INDEX = gql`
  query Query($zoneId: String!) {
    lastSegmentedZoneIndex(zoneId: $zoneId)
  }
`;
