import { Component, Input } from '@angular/core';

import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'breadcrumbs',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './breadcrumbs.component.html',
})
export class BreadcrumbsComponent {
  @Input() pages: string[] = [];
}
