import { Component, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { OtpModalContentComponent } from '../otp-modal-content/otp-modal-content.component';
import { DialogThinkingComponent } from '../dialog-thinking/dialog-thinking.component';
import {
  TActionOPT,
  TParamsCreateAppointment,
  TStateConfirmModal,
} from '@core/shared/types';

type TParamsData = { data: TParamsCreateAppointment };

@Component({
  selector: 'confirm-create-appointment',
  standalone: true,
  imports: [DialogThinkingComponent, OtpModalContentComponent],
  templateUrl: './confirm-create-appointment.component.html',
})
export class ConfirmCreateAppointmentComponent {
  private readonly router = inject(Router);
  private readonly dialogRef = inject(
    MatDialogRef<ConfirmCreateAppointmentComponent>,
  );
  readonly paramsData = inject<TParamsData>(MAT_DIALOG_DATA);
  private readonly $stateModal = signal<TStateConfirmModal>('IDLE');

  get appointment() {
    return this.paramsData.data?.appointment;
  }

  get phone() {
    return this.paramsData.data?.phone;
  }

  get currentState(): TStateConfirmModal {
    return this.$stateModal();
  }

  ngOnInit(): void {
    this.$stateModal.set(this.paramsData.data?.type);
  }

  onClose() {
    this.dialogRef.close();
  }

  onCreate() {
    this.onClose();
    this.router.navigate(['/scheduler/view']);
  }

  optConfirm(event: TActionOPT) {
    switch (event.action) {
      case 'CLOSE_MODAL':
        this.onClose();
        return;

      case 'CONFIRMED_OTP':
        this.$stateModal.set('CONFIRM');
        return;
    }
  }
}

// /scheduling/schedules/validate-account-contract
