<article class="flex h-screen w-screen max-w-screen-xxl">
  <section class="lg:w-2/6 xl:w-5/12 relative pt-2 pb-2 pl-2 xl:pt-4 xl:pb-4 xl:pl-4 xxl:pt-6 xxl:pb-6 xxl:pl-6">
    <div class="bg-gradient-to-b from-[#01549C] to-[#003258]  height-screen rounded-2xl">
      <div class="flex flex-col gap-5 xl:gap-7 pt-10 xl:pt-12">
        <picture class="flex justify-end pr-8">
          <img class="lg:w-5/12" src="assets/images/arrow_home.png" alt="arrow home image" />
        </picture>
        <h2 class="text-center lg:text-3xl xl:text-5xl xxl:text-6xl text-white font-bold">Agendamiento</h2>
      </div>
    </div>
    <picture class="absolute bottom-0 flex left-0 lg:justify-start xxl:justify-center w-full">
      <img
        class="w-full"
        src="assets/images/hogares_tarjetas_experto.png"
        alt="experts home"
      />
    </picture>
  </section>
  <section class="lg:w-4/6 xl:w-7/12 flex flex-col gap-12 justify-center items-center">
    <picture class="flex justify-center">
      <img src="assets/images/vanti-logo-full.png" alt="vanti logo with copy" />
    </picture>
    <div class="border-black border rounded-2xl lg:p-10 xl:p-12">
      <div class="flex flex-col gap-4 w-80 xxl:w-96">
        <h1
          class="text-center text-2xl font-semibold xl:text-3xl xxl:text-4xl xl:font-bold xxl:font-extrabold text-accent"
        >
          Inicio de sesión
        </h1>
        <p class="text-neutral-50 text-center">
          ¡Nos complace darte la bienvenida! <br />
          Ingresa y realiza tus agendamientos de la mejor manera en nuestra
          plataforma.
        </p>

        @if (canShowRoleSelector) {
          <sm-select
            [options]="options"
            [formControl]="role"
          />
        }
        
        <button class="btn btn-primary" [disabled]="isFetching()" (click)="onSignIn()">
          @if(isFetching()) {
            Espera...
          } @else {
            Ingresar
          }
        </button>
      </div>
    </div>
  </section>
</article>
