import { ISegmentedNet, ISegmentedZone, IZone } from '../interfaces';
import { listSegmentedNets } from './segmented-nets.entity';
import { ZoneEntity } from './zone.entity';

export const listSegmentedZone = (list: Partial<ISegmentedZone>[]) =>
  list?.map((item) => SegmentedZoneEntity.fromJson(item).toJson()) ?? [];

export class SegmentedZoneEntity implements ISegmentedZone {
  readonly id: string;
  readonly created_at: string;
  readonly updated_at: string;
  readonly code: string;
  readonly name: string;
  readonly zone: IZone;
  readonly segmentedNets: ISegmentedNet[];

  private constructor(segmentedZone: ISegmentedZone) {
    this.id = segmentedZone.id;
    this.created_at = segmentedZone.created_at;
    this.updated_at = segmentedZone.updated_at;
    this.code = segmentedZone.code;
    this.name = segmentedZone.name;
    this.zone = segmentedZone.zone;
    this.segmentedNets = segmentedZone.segmentedNets;
  }

  static fromJson(segmentedZone: Partial<ISegmentedZone>) {
    return new SegmentedZoneEntity({
      id: segmentedZone.id ?? '',
      created_at: segmentedZone.created_at ?? '',
      updated_at: segmentedZone.updated_at ?? '',
      code: segmentedZone.code ?? '',
      name: segmentedZone.name ?? '',
      zone: ZoneEntity.fromJson(segmentedZone.zone ?? {}).toJson(),
      segmentedNets: listSegmentedNets(segmentedZone.segmentedNets),
    });
  }

  toJson(): ISegmentedZone {
    return {
      ...this,
    };
  }
}
