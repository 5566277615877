import { TAppModules } from '../interfaces';
import { TSvgIcons } from '../types';

export const PAGE_PATHS: Record<TAppModules, string> = {
  agendar: 'scheduler',
  capacidades: 'capacity',
  administrar: 'admin',
};

export const ROLE_LABEL: Record<TAppModules, string> = {
  agendar: 'Agendar',
  capacidades: 'Gestión de capacidades',
  administrar: 'Administración',
};

export const PAGE_ICONS: Record<TAppModules, TSvgIcons> = {
  agendar: 'calendar-content',
  capacidades: 'round-check',
  administrar: 'pencil',
};

export function getPagePath(path: string) {
  return path in PAGE_PATHS ? `/${PAGE_PATHS[path]}` : '/not-found';
}

export function getIconPath(icon: string) {
  return icon in PAGE_ICONS ? PAGE_ICONS[icon] : ('' as TSvgIcons);
}
