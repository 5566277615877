export function fileSize(size: number) {
  if (size < 1000) {
    return `${size} bytes`;
  } else if (size >= 1000 && size < 1000000) {
    return `${Math.floor(size / 1000)}KB`;
  } else {
    return `${Math.floor(size / 1000000)}MB`;
  }
}

export function fileNameLimit(name: string, limit: number) {
  if (!name || !name.includes('.') || limit === 0) {
    return '';
  }

  const nameItems = name.split('.');
  const extension = nameItems.at(-1)!;
  const nameText = nameItems.slice(0, -1);

  return name.length > limit
    ? `${nameText.join('.').slice(0, limit)}....${extension}`
    : name;
}
