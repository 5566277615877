import { Component, Input } from '@angular/core';

@Component({
  selector: 'dialog-thinking',
  standalone: true,
  imports: [],
  templateUrl: './dialog-thinking.component.html',
})
export class DialogThinkingComponent {
  @Input() title: string;
  @Input() description: string;
}
