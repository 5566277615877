import {
  Component,
  computed,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'opt-input',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './opt-input.component.html',
})
export class OptInputComponent implements OnChanges, OnInit, OnDestroy {
  $subs?: Subscription;
  formGroupOtp: FormGroup = new FormGroup({});
  readonly $length = signal(4);

  @Input() set length(len: number) {
    this.$length.set(len);
  }

  @Output() onValueChange = new EventEmitter<string>();

  readonly controls = computed(() => {
    return Object.keys(this.formGroupOtp.controls);
  });

  ngOnChanges(_: SimpleChanges): void {
    const controls: Record<string, FormControl> = {};

    for (let index = 0; index < this.$length(); index++) {
      controls[`digit_${index}`] = new FormControl('', [Validators.required]);
    }

    this.formGroupOtp = new FormGroup(controls);
  }

  ngOnInit() {
    this.$subs = this.formGroupOtp?.valueChanges?.subscribe((value) => {
      const otpCode = Object.values(value).join('');

      if (otpCode.length === this.$length()) {
        this.onValueChange.emit(otpCode);
      } else {
        this.onValueChange.emit('');
      }
    });
  }

  ngOnDestroy(): void {
    this.$subs?.unsubscribe();
  }
}
