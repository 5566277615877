<section>
  @switch (currentState) {
    @case ('CONFIRM') {
      <div class="p-4">
        <!-- <dialog-thinking
          title="Confirmación cita"
          description="Estas agendando una cita programada para {{ appointment?.date }}, {{ appointment?.label }}"
        >
          <strong class="my-3">¿Estás Seguro?</strong>
          <div class="flex justify-around gap-3">
            <button class="btn btn-outline min-w-32" (click)="onClose()">Cerrar</button>
            <button class="btn btn-primary min-w-32" (click)="onCreate()">Confirmar</button>
          </div>
        </dialog-thinking> -->
      </div> 
    }

    @case ('CREATE_WITH_TOKEN') {
      <!-- <otp-modal-content [phone]="phone" (onConfirm)="optConfirm($event)" /> -->
    }
  }
</section>