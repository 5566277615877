<form [formGroup]="formGroupOtp" class="mt-5">
  <div class="flex space-x-2 justify-center items-center">
    @for (ctrlName of controls(); track $index) {
      <input
        type="text"
        maxlength="1"
        class="shadow-md rounded-xl p-5 w-16 font-bold text-center border text-3xl border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
        aria-label="Código OTP"
        [formControlName]="ctrlName"
        onfocus="this.select();"
      />
    }
  </div>
</form>
