import { inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { map } from 'rxjs';

import { UserStateService } from './user-state.service';
import { createMenuModules } from '@core/domain/builders';

@Injectable({
  providedIn: 'root',
})
export class UiStateService {
  private readonly router = inject(Router);
  private readonly userState = inject(UserStateService);
  private $loadingStack = signal<string[]>([]);
  private $isSidebarOpen = signal<boolean>(false);

  readonly $isLaziLoading = toSignal(
    this.router.events.pipe(
      map((event) => {
        if (event instanceof RouteConfigLoadStart) {
          return true;
        } else if (event instanceof RouteConfigLoadEnd) {
          return false;
        }
        return false;
      }),
    ),
  );

  get loadingStack() {
    return this.$loadingStack();
  }

  get isSidebarOpen() {
    return this.$isSidebarOpen();
  }

  get sidebarModules() {
    return createMenuModules(this.userState.authUser().permissionModules);
  }

  public setLoadingStack(item: string) {
    this.$loadingStack.update((stack) => [...stack, item]);
  }

  public setIsSidebarOpen(isOpen: boolean) {
    this.$isSidebarOpen.set(isOpen);
  }
}
