import {
  Component,
  computed,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';

import { TOption, TPageSize, TPaginator } from '@core/domain/types';
import { SmSelectComponent } from '../sm-select/sm-select.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'sm-paginator',
  standalone: true,
  imports: [SmSelectComponent, SvgIconComponent],
  templateUrl: './sm-paginator.component.html',
  styleUrl: './sm-paginator.component.css',
})
export class SmPaginatorComponent {
  readonly $totalPages = signal<number>(0);
  readonly $totalItems = signal<number>(0);
  readonly $pageSize = signal<TPageSize>('10');
  readonly $pageSizeOptions = signal<number[]>([]);
  readonly $page = signal<number>(1);

  readonly $listOptions = computed(() =>
    this.$pageSizeOptions().map<TOption>((page) => ({
      value: `${page}`,
      label: `${page}`,
    })),
  );

  @Output() changePage = new EventEmitter<Partial<TPaginator>>();

  @Input() set totalPages(totalPages: number) {
    this.$totalPages.set(totalPages);
  }

  @Input() set totalItems(totalItems: number) {
    this.$totalItems.set(totalItems);
  }

  @Input() set page(page: number) {
    this.$page.set(page);
  }

  @Input() set pageSize(pageSize: TPageSize) {
    this.$pageSize.set(pageSize);
  }

  @Input() set pageSizeOptions(pages: number[]) {
    this.$pageSizeOptions.set(pages);
  }

  readonly $pageList = computed(() => {
    return Array.from({ length: this.$totalPages() }, (_, idx) => idx + 1);
  });

  readonly $isDisabledPrev = computed(() => {
    return this.$page() === 1;
  });

  readonly $idDisabledNext = computed(() => {
    return this.$page() === this.$totalPages();
  });

  setPage(page: number) {
    this.changePage.emit({ page });
  }

  setPageSize(pageSize: TPageSize) {
    this.changePage.emit({ pageSize });
  }

  prevPage() {
    if (this.$isDisabledPrev()) {
      return;
    }

    this.setPage(this.$page() - 1);
  }

  nextPage() {
    if (this.$idDisabledNext()) {
      return;
    }

    this.setPage(this.$page() + 1);
  }

  changeLimit(pageSize: string) {
    this.changePage.emit({ pageSize: pageSize as TPageSize });
  }
}
