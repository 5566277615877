import { Component, OnInit, inject } from '@angular/core';

import { AuthBlocService } from '@core/blocs';
import { SpinnerComponent } from '@core/shared/components';

@Component({
  selector: 'page-auth-verify',
  standalone: true,
  imports: [SpinnerComponent],
  templateUrl: './auth-verify.component.html',
})
export class AuthVerifyComponent implements OnInit {
  private readonly authBloc = inject(AuthBlocService);

  ngOnInit(): void {
    this.authBloc.getTokenFromUrl();
  }
}
