import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { UserStateService } from '@core/states';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const userService = inject(UserStateService);

  const token = userService.token;

  if (!token) {
    return next(req);
  }

  const clonedRequest = req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });

  return next(clonedRequest);
};
