import { inject, Injectable } from '@angular/core';
import { catchError, map, of } from 'rxjs';

import { CapabilitiesService } from '../modules/capacities/services';
import { ICapabilitiesFilters } from '@core/modules/capacities/data/types';

@Injectable({
  providedIn: 'root',
})
export class CapabilitiesBlocService {
  private readonly capabilitiesService = inject(CapabilitiesService);

  initCapabilitiesData(filters: ICapabilitiesFilters) {
    return this.capabilitiesService.getFiltersData(filters).pipe(
      map((data) => ({
        success: true,
        data: {
          operations: data.listOperations,
          zones: data.zoneList,
          data: data.capabilitiesByMonth,
        },
      })),
      catchError((err) =>
        of({
          success: false,
          message: err.message,
          data: { operations: [], zones: [], data: [] },
        }),
      ),
    );
  }

  initCapabilitiesViewDataByZoneId(
    zoneId: string,
    filters: ICapabilitiesFilters,
  ) {
    return this.capabilitiesService
      .getCapabilitiesViewDataByZoneId(zoneId, filters)
      .pipe(
        map((data) => ({
          success: true,
          data: {
            segmentedZones: data.segmentedZones.data,
            capabilitiesByDay: data.capabilitiesByDay,
            capabilitiesSegmentedBags: data.capabilitiesSegmentedBags,
          },
        })),
        catchError((err) =>
          of({
            success: false,
            message: err.message,
            data: {
              segmentedZones: [],
              capabilitiesByDay: [],
              capabilitiesSegmentedBags: [],
            },
          }),
        ),
      );
  }

  filterChartData(filters: ICapabilitiesFilters) {
    return this.capabilitiesService.getFiltersChartData(filters).pipe(
      map((data) => ({
        success: true,
        data: data.capabilitiesByMonth,
      })),
      catchError((err) =>
        of({
          success: false,
          message: err.message,
          data: [],
        }),
      ),
    );
  }
}
