export const UPLOAD_ERROR_TEXTS: Record<string, string> = {
  'SMG-001': 'La fecha debe ser igual o mayor a la fecha actual',
  'SMG-002': 'El formato de fecha no es válido',
  'SMG-003': 'La fecha inicial no puede ser mayor a la fecha final',
  'SMG-004':
    'La cantidad de días entre hoy y la fecha final no puede superar 210 días calendario',
  'SMG-005': 'El valor proporcionado no está definido en el sistema',
  'SMG-006': 'El valor ingresado debe ser mayor o igual a cero',
  'SMG-007': 'El valor ingresado debe encontrarse entre el rango 1-100',
  'SMC-001': 'La capacidad ya fue cargada previamente en rango de fechas',
};
