import { getPayload } from '../helpers';
import {
  IModuleUser,
  IPayloadUserToken,
  IPermissionsUser,
  IUser,
  TAppRoles,
} from '../interfaces';
import { toTitleCase } from '../helpers/text.helper';

export class AuthUser implements IPayloadUserToken {
  readonly name: string;
  readonly email: string;
  readonly roles: TAppRoles[];
  readonly groups: string[];
  readonly application: string;
  readonly typeOrigin: string;
  readonly permissions: IPermissionsUser;
  readonly iat: number;

  private constructor(payload: IPayloadUserToken) {
    this.name = payload.name;
    this.email = payload.email;
    this.roles = payload.roles;
    this.groups = payload.groups;
    this.application = payload.application;
    this.typeOrigin = payload.typeOrigin;
    this.permissions = payload.permissions;
    this.iat = payload.iat;
  }

  static fromToken(token: string) {
    const payload = getPayload(token);

    return new AuthUser({
      name: payload?.name ?? '',
      email: payload?.email ?? '',
      roles: payload?.roles ?? [],
      groups: payload?.groups ?? [],
      application: payload?.application ?? '',
      typeOrigin: payload?.typeOrigin ?? '',
      permissions: payload?.permissions ?? ({} as IPermissionsUser),
      iat: payload?.iat ?? 0,
    });
  }

  get userRole(): TAppRoles {
    return this.permissions.role;
  }

  get user(): IUser {
    return {
      email: this.email,
      name: this.name,
      role: toTitleCase(this.permissions?.role ?? ('' as TAppRoles)),
    };
  }

  get permissionModules(): IModuleUser {
    return this.permissions?.module?.at(0);
  }
}
