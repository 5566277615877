import { gql } from 'apollo-angular';

export const MUTATION_UPLOAD_CAPACITY_FILE = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file) {
      fileErrors {
        code
        count
        message
        rows
      }
      status
      message
      errorCode
    }
  }
`;
