import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { interval, map, take } from 'rxjs';

import { OptInputComponent } from '../opt-input/opt-input.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { MaskedTextPipe } from '@core/shared/pipes';
import { TActionOPT } from '@core/shared/types';
@Component({
  selector: 'otp-modal-content',
  standalone: true,
  imports: [NgClass, OptInputComponent, SvgIconComponent, MaskedTextPipe],
  templateUrl: './otp-modal-content.component.html',
})
export class OtpModalContentComponent {
  @Output() onConfirm = new EventEmitter<TActionOPT>();
  readonly $phone = signal('');
  readonly $codeOtp = signal('');

  readonly $timer = toSignal(
    interval(1000).pipe(
      take(60),
      map((sec) => 59 - sec),
    ),
    { initialValue: 60 },
  );

  @Input() set phone(phone: string) {
    this.$phone.set(phone);
  }

  otpCode(code: string) {
    this.$codeOtp.set(code);
  }

  closeModal() {
    this.onConfirm.emit({ action: 'CLOSE_MODAL' });
  }

  onConfirmOPTValid() {
    this.onConfirm.emit({ action: 'CONFIRMED_OTP' });
  }
}
