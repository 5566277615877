import { Injectable, inject } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Observable,
  Subscription,
  catchError,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { IResponseMap } from '@core/domain/interfaces';
import { createMenuModules, userBuilderFn } from '@core/domain/builders';
import { UiStateService, UserStateService } from '@core/states';
import {
  NotifyService,
  SessionTimerService,
  StorageService,
} from '@core/shared/services';
import { getPayload } from '@core/domain/helpers';
import { AuthService } from '@core/modules/auth/services/auth.service';
import { TUserInfoDataToken } from '@core/modules/auth/types/auth.types';
import { TRole } from '@core/domain/types';

@Injectable({
  providedIn: 'root',
})
export class AuthBlocService {
  private readonly authService = inject(AuthService);
  private readonly userState = inject(UserStateService);
  private readonly storage = inject(StorageService);
  private readonly router = inject(Router);
  private readonly routerActive = inject(ActivatedRoute);
  private readonly uiState = inject(UiStateService);
  private readonly notify = inject(NotifyService);
  private readonly sessionTimer = inject(SessionTimerService);
  private readonly location = inject(Location);
  private readonly document = inject(DOCUMENT);

  async initAuthFromStore() {
    const token = await this.storage.getItem('token');

    await this.userState.setToken(token);
  }

  getUrlAuth(role: TRole): Observable<IResponseMap<null>> {
    return this.authService.getAuthUrl(role).pipe(
      tap((res) => {
        const { url } = res.data;

        this.document.location.replace(url);
      }),
      map(() => ({ success: true, data: null })),
      catchError((err) => {
        return of({ success: false, message: err?.message, data: null });
      }),
    );
  }

  getUserInfoData(token: string) {
    return this.authService.validateToken(token).pipe(
      map<{ token: string }, TUserInfoDataToken>(({ token }) => {
        const payload = getPayload(token);

        return {
          user: userBuilderFn(payload),
          modules: createMenuModules(payload?.permissions?.module?.at(0)),
          token,
        };
      }),
    );
  }

  getTokenFromUrl() {
    return this.routerActive.queryParams
      .pipe(
        filter(({ token }) => !!token),
        switchMap(({ token }) => this.getUserInfoData(token)),
      )
      .subscribe({
        next: async (resp) => {
          this.userState.setToken(resp.token);
          await this.storage.setItem('token', resp.token);

          this.router.navigate(['scheduler'], { replaceUrl: true });
        },
        error: async (err) => {
          this.notify.error('Espera!', err.message);
          await this.storage.clear();
          this.userState.clean();
          this.router.navigate(['login'], { replaceUrl: true });
        },
      });
  }

  doSignOut() {
    return this.authService
      .logout()
      .pipe(
        take(1),
        map((data) => ({ ...data, success: true })),
        catchError(() => of({ success: false })),
      )
      .subscribe(() => {
        this.storage.clear();
        this.userState.clean();
        this.router.navigate(['login'], { replaceUrl: true });
      });
  }

  closeSessionTimer() {
    let suscription: Subscription | null = null;

    this.location.onUrlChange((url) => {
      suscription?.unsubscribe();

      if (['/login'].includes(url)) {
        return;
      }

      suscription = this.sessionTimer
        .run()
        .pipe(take(1))
        .subscribe((event) => {
          this.notify.info('Fin de la sesión', event);
          this.doSignOut();
        });
    });
  }
}
