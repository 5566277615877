import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  model,
  Output,
} from '@angular/core';
import {
  MatCalendarCellClassFunction,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import {
  MAT_DATE_LOCALE,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'sm-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrl: './datepicker.component.css',
  standalone: true,
  providers: [
    provideNativeDateAdapter(),
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ],
  imports: [MatDatepickerModule, MatCardModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent {
  today: Date = new Date();
  userSelectDate = model<Date | null>(null);

  @Input() selectedDates: Date[] = [];
  @Output() dateSelected = new EventEmitter<Date>();

  onDateSelected(selectedDate: Date): void {
    const index = this.selectedDates.findIndex(
      (date) => date.getTime() === selectedDate.getTime(),
    );

    if (index >= 0) {
      this.userSelectDate = null;
      this.dateSelected.emit(null);
    } else {
      this.dateSelected.emit(selectedDate);
    }
  }

  dateClass: MatCalendarCellClassFunction<Date> = (date: Date) => {
    if (this.selectedDates.some((d) => d.getTime() === date.getTime()))
      return 'occupied-dates';
    if (date.toDateString() === this.today.toDateString()) return 'today-date';
    return '';
  };
}
