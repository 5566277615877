import { Injectable, inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  private readonly toastr = inject(ToastrService);

  private config = {};

  done(title: string, message: string) {
    return this.toastr.success(message, title);
  }

  error(title: string, message: string) {
    return this.toastr.error(message, title);
  }

  info(title: string, message: string) {
    return this.toastr.info(message, title);
  }

  warn(title: string, message: string) {
    return this.toastr.warning(message, title);
  }
}
