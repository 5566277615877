import { Injectable } from '@angular/core';
import { debounceTime, fromEvent, map, merge, of } from 'rxjs';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class SessionTimerService {
  run() {
    return merge(
      fromEvent(window, 'mouseover'),
      fromEvent(window, 'keypress'),
      fromEvent(window, 'touchstart'),
      fromEvent(window, 'blur'),
      of('load'),
    ).pipe(
      debounceTime(environment.inactiveTimer),
      map(
        () =>
          `Llevas ${environment.inactiveTimer / 60 / 1000} minutos sin actividad`,
      ),
    );
  }
}
