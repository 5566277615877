import { Injectable, computed, signal } from '@angular/core';

import { AuthUser } from '@core/domain/entities';
import { getPayload } from '@core/domain/helpers';
import { IPermissionsUser } from '@core/domain/interfaces';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class UserStateService {
  private readonly $token = signal<string>('');

  readonly authUser = computed(() => {
    return AuthUser.fromToken(this.$token());
  });

  readonly isAuthActive = computed(() => {
    const payload = getPayload(this.$token());

    if (!payload) {
      return false;
    }

    const currentTime = Math.floor(Date.now() / 1000);
    const limitInSeconds = environment.limitTokenInHours * 60 * 60;
    const isExpiredToken = currentTime - payload!.iat > limitInSeconds;
    const isUserActive = [!!this.$token(), !isExpiredToken].every(Boolean);

    return isUserActive;
  });

  readonly permissions = computed<IPermissionsUser>(() => {
    const authUser = AuthUser.fromToken(this.$token());

    return {
      role: authUser.userRole,
      module: [authUser.permissionModules],
    } as IPermissionsUser;
  });

  public get token() {
    return this.$token();
  }

  public setToken(token: string) {
    this.$token.set(token);
  }

  public clean() {
    this.$token.set('');
  }
}
