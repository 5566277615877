<div
  class="side-menu-item flex rounded-lg px-2 py-2 w-full hover:bg-neutral-40 cursor-pointer"
  (click)="doNavigateAction()"
  [ngClass]="{'active': isActiveRoute }"
>
  <div class="w-full flex items-center py-1 row-content">
    <div
      class="flex items-center justify-center rounded-sm px-2 content-active"
    >
      <span class="w-7 h-7 cursor-pointer">
        <svg-icon [icon]="item?.icon" />
      </span>
    </div>
    <p
      class="text-accent-clear font-normal content-active-text flex-1"
      [ngClass]="{ hidden: !isOpen }"
    >
      {{ item?.label }}
    </p>
  </div>
</div>
