import { Component, computed, inject } from '@angular/core';
import { NgClass } from '@angular/common';

import { environment } from '@env/environment';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { SideMenuItemComponent } from '../side-menu-item/side-menu-item.component';
import { UiStateService } from '@core/states';
import { AuthBlocService } from '@core/blocs';
import { TSvgIcons } from '@core/domain/types';

@Component({
  selector: 'side-menu',
  standalone: true,
  imports: [SvgIconComponent, NgClass, SideMenuItemComponent],
  templateUrl: './side-menu.component.html',
})
export class SideMenuComponent {
  private readonly uiState = inject(UiStateService);
  private readonly authBloc = inject(AuthBlocService);

  isOpen = computed(() => this.uiState.isSidebarOpen);

  get listItems() {
    return this.uiState.sidebarModules;
  }

  get iconMenu(): TSvgIcons {
    return this.isOpen() ? 'x-icon' : 'menu';
  }

  get version() {
    return `Versión ${environment.version}`;
  }

  get copyRight() {
    const date = new Date();
    return `Vanti ${date.getFullYear()} © Copyright`;
  }

  doCheck() {
    this.uiState.setIsSidebarOpen(!this.isOpen());
  }

  doLogout() {
    this.authBloc.doSignOut();
  }
}
