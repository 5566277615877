import { FILES_ALLOWED } from '../constants';
import { fileNameLimit, fileSize } from '../helpers';

export class FileUpload {
  // eslint-disable-next-line no-empty-function
  constructor(public readonly file: File) {}

  public getName(limit = 20) {
    return fileNameLimit(this.file.name, limit);
  }

  public getSize() {
    return fileSize(this.file.size);
  }

  public hasError() {
    return !!this.errorText();
  }

  public errorText(): string {
    if (!FILES_ALLOWED.includes(this.file.type)) {
      return 'El archivo no tiene el formato .csv o .xlsx';
    }

    if (this.file.size > 20000000) {
      return 'El archivo es demasiado grande';
    }

    return '';
  }
}
