import { IZone } from '../interfaces';

export const listZone = (list: Partial<IZone>[]) =>
  list?.map((item) => ZoneEntity.fromJson(item).toJson()) ?? [];

export class ZoneEntity implements IZone {
  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly created_at: string;
  readonly updated_at: string;

  private constructor(zone: IZone) {
    this.id = zone.id;
    this.code = zone.code;
    this.name = zone.name;
    this.created_at = zone.created_at;
    this.updated_at = zone.updated_at;
  }

  static fromJson(zone: Partial<IZone>) {
    return new ZoneEntity({
      id: zone.id ?? '',
      code: zone.code ?? '',
      name: zone.name ?? '',
      created_at: zone.created_at ?? '',
      updated_at: zone.updated_at ?? '',
    });
  }

  toJson(): IZone {
    return {
      ...this,
    };
  }
}
