import { inject, Injectable } from '@angular/core';
import { catchError, map, of, take } from 'rxjs';
import { Apollo } from 'apollo-angular';

import { MUTATION_UPLOAD_CAPACITY_FILE } from '../data/gql';
import { TUploadFileCapabilitiesResponse } from '@core/domain/types';
import { UploadFileResponseEntity } from '../data/entities';

@Injectable({
  providedIn: 'root',
})
export class UploadCapacityFileService {
  private readonly apolloClient = inject(Apollo);

  uploadCapacity(file: File) {
    return this.apolloClient
      .mutate({
        mutation: MUTATION_UPLOAD_CAPACITY_FILE,
        variables: {
          file,
        },
        context: {
          useMultipart: true,
          headers: {
            'apollo-require-preflight': 'true',
          },
        },
      })
      .pipe(
        take(1),
        map((resp) =>
          UploadFileResponseEntity.fromJson(
            (resp.data as { uploadFile: TUploadFileCapabilitiesResponse })
              .uploadFile,
          ),
        ),
        map((resp) => ({
          success: resp.isSuccess(),
          data: resp?.fileErrors,
          message: resp.message,
        })),
        catchError((error) =>
          of({ success: false, message: error.message, data: null }),
        ),
      );
  }
}
