import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { AuthBlocService } from './blocs';
import { environment } from '@env/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private readonly authBloc = inject(AuthBlocService);
  readonly title = 'sm-backoffice-sm';

  ngOnInit(): void {
    console.log('version: ', environment.version);
    this.authBloc.initAuthFromStore();
    this.authBloc.closeSessionTimer();
  }
}
