import { gql } from 'apollo-angular';

export const QUERY_GET_URL_REDIRECT = gql`
  query RedirectUrl($loginRedirectRequestDto: RequestUrlDto!) {
    redirectUrl(loginRedirectRequestDto: $loginRedirectRequestDto) {
      url
    }
  }
`;

export const QUERY_GET_AUTH_USER = gql`
  query AuthUser($authUserDto: AuthUserDto!) {
    authUser(authUserDto: $authUserDto) {
      token
    }
  }
`;

export const MUTATION_LOGOUT = gql`
  mutation Logout {
    logout {
      message
    }
  }
`;
