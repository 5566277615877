export function toTitleCase(text: string) {
  return !!text ? `${text[0].toLocaleUpperCase()}${text.slice(1)}` : '';
}

export function obscureText(text: string, init = 3, end = 0) {
  if (!text) {
    return '';
  }

  const len = text.length;

  if (init > len || end >= len || end + init > len) {
    throw new Error('init/end doesn`t be greater than text length');
  }

  const start = text.slice(0, init);
  const finish = end > 0 ? text.slice(end * -1) : '';
  const masked = '*'.repeat(len - init - end);

  return `${start}${masked}${finish}`;
}
