<div class="sm-select" [class.disabled]="isDisabled">
  <button
    type="button"
    class="sm-select-label"
    (click)="toggleDropdown()"
    [disabled]="isDisabled"
    aria-haspopup="true"
  >
    @if (!!$value()) {
      <span class="text">{{ $label() }}</span>
    } @else {
      <span class="placeholder">{{ placeholder }}</span>
    }
    <span class="icon">
      <svg-icon [icon]="$icon()" />
    </span>
  </button>

  @if ($isDropdownOpen()) {
    <ul
      role="listbox"
      class="sm-select-listbox"
      [attr.aria-expanded]="$isDropdownOpen()"
      [attr.aria-hidden]="!$isDropdownOpen()"
    >
      @for (option of $options(); track option) {
        <li
          class="sm-select-option"
          [class.selected]="option.value === $value()"
          (click)="selectOption(option)"
          tabindex="0"
          [attr.aria-selected]="option.value === $value()"
          role="option"
          (keydown)="onKeydown($event, option)"
        >
          {{ option.label }}
        </li>
      }
    </ul>
  }
</div>
