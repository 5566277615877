import { getIconPath, getPagePath, ROLE_LABEL, toTitleCase } from '../helpers';
import {
  IModuleItem,
  IModuleUser,
  IPayloadUserToken,
  IUser,
} from '../interfaces';

export function userBuilderFn(
  userAuth: Partial<IPayloadUserToken> = {} as Partial<IPayloadUserToken>,
): IUser {
  return {
    email: userAuth?.email ?? '',
    name: userAuth?.name ?? '',
    role: toTitleCase(userAuth?.permissions?.role ?? ''),
  };
}

export function createMenuModules(modules: IModuleUser): IModuleItem[] {
  return Object.entries(modules ?? {}).map(([key, listPermissions]) => ({
    key,
    label: ROLE_LABEL[key] ?? '',
    url: getPagePath(key),
    icon: getIconPath(key),
    permissions: listPermissions ?? [],
  }));
}
